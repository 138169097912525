export default defineNuxtRouteMiddleware((to, from) => {
  const { $pinia, $localePath } = useNuxtApp()
  const draftStore = useDraftStore($pinia)
  const draftFlowStore = useDraftFlowStore($pinia)

  // Redirect to the correct step based on the draft step status
  const needsToChooseStep = to.path?.endsWith(`${draftFlowStore.path}`)
  if (needsToChooseStep) {
    draftFlowStore.GO_TO_FURTHEST_STEP(draftStore.step_status)
    return navigateTo($localePath(draftFlowStore.GET_ACTIVE_STEP_URL()))
  }

  const isOnSameStep = to.path === from.path
  if (isOnSameStep) return

  // Redirect to the correct step if the user is not on a sub-page or uses legacy step query param
  // should only perform a redirect when the route has a "step" query param
  if (to.query?.step && !Number.isNaN(parseInt(to.query?.step.toString())))
    draftFlowStore.SET_ACTIVE_STEP(parseInt(to.query.step.toString()))
})
